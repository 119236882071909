<template>
  <div class="warp">
    <van-tabs v-model:active="active" type="card" style="margin-bottom: 20px">
      <van-tab title="离线设备" name="1"></van-tab>
      <van-tab title="断货预警设备" name="2"></van-tab>
      <van-tab title="滞留预警设备" name="3"></van-tab>
    </van-tabs>
    <div v-for="(item, index) in tableList[active - 1]" :key="index" class="item" @click="toDetail(item)">
      <div class="title" v-if="active == 1">
        编号：{{ item.vmcName }}
        <br/>
        点位名称：{{ item.address }}
        <br/>
        网点名称： {{ item.netName }}
        <br />
        离线时间：{{ item.updateTime }}
      </div>
<!--      <div class="title" v-if="active == 2">-->
<!--        编号：{{ item.vmcName }}-->
<!--        <br/>-->
<!--        点位名称：{{ item.address }}-->
<!--        <br/>-->
<!--        网点名称： {{ item.netName }}-->
<!--      </div>-->
      <div class="title" v-if="active == 2">
        编号：{{ item.vmcName }}
        <br/>
        点位名称：{{ item.address }}
      </div>
      <div class="title" v-if="active == 3">
        编号：{{ item.vmcName }}
        <br/>
        网点名称： {{ item.netName }}
        <br/>
        点位名称：{{ item.address }}
      </div>
      <div v-if="active==1" class="_F4730C out-line">离线中</div>
      <van-icon name="arrow" class="arrow" />
    </div>
    <div v-if="tableList[active-1]?.length == 0 || retentionVmcInfos.length == 0" style="text-align: center;color:#999">没有数据了</div>
  </div>
</template>

<script>
import { homeInfos, offlineInfos } from "../../assets/api/index.js";
export default {
  data() {
    return {
      active: this.$route.query.active,
      vmcIds: [],
      retentionVmcInfos: [],
      tableList: []
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      homeInfos().then(res => {
        if (res.data.code == 200) {
          // this.vmcIds = res.data.data.outStockInfos
          this.tableList.push(res.data.data.vmcInfoStatisticsInfos)
          this.tableList.push(res.data.data.retentionVmcInfos)
          this.getOfflineInfos()
        }
      })
    },
    getOfflineInfos() {
      offlineInfos().then(res => {
        this.tableList.unshift(res.data.data)
      })
    },
    toDetail(item) {
      this.$router.push({
        name: 'net_point_detail_infos',
        query: {
          vmcId: item.vmcId
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.warp {
  padding: 15px 10px;


  .item {
    padding: 10px 12px;
    border-bottom: 1px solid #dcdee0;
    position: relative;

    .title {
      font-size: 16px;
    }
    .infos {
      height: 24px;
      line-height: 24px;
      .span {
        margin-right: 10px;
        color: #666;
      }
    }
    .out-line {
      position: absolute;
      right: 20px;
      top: 38px;
      font-size: 14px;
    }
    .arrow {
      position: absolute;
      right: 0;
      top: 40%;
      font-size: 16px;
      color: #999
    }
  }
}
</style>
